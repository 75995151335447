<template>
  <div
    class="full-page"
  >
    <div
      class="wrapper wrapper-full-page"
    >
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
        :style="setBgImage"
      >
        <div
          class="container md-offset"
          :class="{
            'pb-5': ($route.name === 'LetterVerification'),
          }"
        >
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <lock-card>
              <h2
                slot="title"
                class="font-weight-bold mb-2 mt-3 opacity-7"
              >
                Error 404 - Page Not found
              </h2>

              <template slot="content">
                <p>We are sorry, the page you are looking for doesnt exist</p>
                <md-button
                  href
                  class="md-success md-round"
                  @click="toHome"
                >
                  Back to Home
                </md-button>
              </template>
            </lock-card>
          </zoom-center-transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import { LockCard } from '@/components';

export default {
  components: {
    ZoomCenterTransition,
    LockCard,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
    };
  },
  computed: {
    setBgImage() {
      return {
        backgroundImage: 'url(/img/bg-pricing.jpg)',
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    },
  },
  methods: {
    toHome() {
      this.$router.push('/');
    },
  },
};
</script>
